

























import {
  computed,
  defineComponent,
  nextTick,
  ref,
  watch
} from "@vue/composition-api"

import PlayLayout from "@/components/GroupTeams/Common/GameModes/PlayLayout.vue"
import MissionCard from "@/components/GroupTeams/Common/Games/GameCardParts/MissionCard.vue"

import ResizableText from "@/components/GroupTeams/Common/Games/ResizableText.vue"
import TextField from "@/components/GroupTeams/Common/Games/TextField.vue"
import SmartMedia from "@/modules/smart-media/components/SmartMedia.vue"
import useMusicalKeysRef from "./useMusicalKeysRef"
import useMusicalKeysState from "./useMusicalKeysState"
import { useViewer } from "@/use"
import { child, push, serverTimestamp } from "firebase/database"

export default defineComponent({
  name: "MusicalKeysForm",
  components: {
    MissionCard,
    PlayLayout,
    ResizableText,
    TextField,
    SmartMedia
  },
  setup() {
    const answer = ref("")
    const field = ref()

    const { dbRef } = useMusicalKeysRef()

    const {
      updateState,
      audio,
      playing,
      isScribe,
      hasAnswered,
      isFieldDisabled,
      isFirstRoundStep,
      roundAnswers,
      currentText,
      round,
      textHidden
    } = useMusicalKeysState()

    const { viewer } = useViewer()

    const answersRef = computed(() => {
      return child(dbRef.value, "answers")
    })

    watch(answer, value => {
      const text = value.toLocaleLowerCase().trim()
      const textToType = currentText.value.toLocaleLowerCase().trim()
      if (text === textToType && !isFirstRoundStep.value) {
        push(answersRef.value, {
          value: text,
          round: round.value,
          userId: viewer.value?.id,
          teamId: viewer.value?.teamID,
          timestamp: serverTimestamp()
        })
        answer.value = ""
      }
    })

    watch(
      isFieldDisabled,
      value => {
        if (!value && isScribe.value) {
          nextTick(() => {
            field.value?.focus()
          })
        }
      },
      { immediate: true }
    )

    return {
      field,
      playing,
      textHidden,
      audio,
      hasAnswered,
      isFieldDisabled,
      roundAnswers,
      isScribe,
      answer,
      currentText
    }
  }
})

import { db } from "@/firebase"
import useStore from "@/use/useStore"
import { computed } from "@vue/composition-api"
import uuidByString from "uuid-by-string"

export default function useMusicalKeys() {
  const { store } = useStore()
  const mission = computed(() => store.getters.getCurrentMission)
  const roomID = computed(() => store?.getters.game?.id)

  const dbRef = computed(() =>
    db
      .auxiliary()
      .ref(
        `_room/${roomID.value}/musicalkeys/${uuidByString(
          String(mission.value.activityId),
          5
        )}`
      )
  )

  return { dbRef }
}

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "MissionCard",
    { staticClass: "musical-keys-from" },
    [
      _c("ResizableText", {
        staticClass: "musical-keys-from__text",
        attrs: { message: _vm.currentText, transition: false },
      }),
      _vm.isScribe
        ? _c("TextField", {
            ref: "field",
            staticClass: "musical-keys-from__field",
            attrs: {
              size: "sm",
              placeholder: _vm.currentText,
              show: "show",
              status: "active",
              disabled: _vm.isFieldDisabled,
              maxTextLength: 120,
            },
            model: {
              value: _vm.answer,
              callback: function ($$v) {
                _vm.answer = $$v
              },
              expression: "answer",
            },
          })
        : _vm._e(),
      _c("SmartMedia", {
        attrs: { autoplay: "", paused: !_vm.playing, src: _vm.audio },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("UsersSmartGrid", {
    staticClass: "mku-grid",
    attrs: { users: _vm.usersToShow, minWidth: 100, maxWidth: 220 },
    scopedSlots: _vm._u([
      {
        key: "option",
        fn: function (ref) {
          var user = ref.user
          return [
            _c("AppUserCard", {
              staticClass: "mku-grid__item",
              class: {
                "mku-grid__item--wrong":
                  _vm.isLastRoundStep && !_vm.isAnswered(user),
                "mku-grid__item--correct":
                  _vm.isLastRoundStep && _vm.isAnswered(user),
              },
              attrs: { user: user },
            }),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
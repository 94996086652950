




















import { defineComponent } from "@vue/composition-api"

import AppUserCard from "@/components/UserCard/AppUserCard.vue"
import UsersSmartGrid from "@/components/GroupTeams/Common/GameModes/UsersSmartGrid.vue"

import useMusicalKeysState from "./useMusicalKeysState"
export default defineComponent({
  name: "MusicalKeysUsersGrid",
  components: { UsersSmartGrid, AppUserCard },
  setup() {
    const { isAnswered, usersToShow, isLastRoundStep } = useMusicalKeysState()

    return { usersToShow, isAnswered, isLastRoundStep }
  }
})
